import $ from 'jquery';
import popper from 'popper.js';
import bootstrap from 'bootstrap';
import { WOW } from 'wowjs';
import owlCarousel from 'owl.carousel';
import inputmask from 'inputmask/dist/min/jquery.inputmask.bundle.min';

$(function(){

  // Init Companies carousel
  initCompaniesCarousel();

  // Anchor links
  initAnchorLinks();

  // Intro section video
  initIntroVideos();

  // Sending forms to email
  initFormsSending();

  // Init Examples carousel
  initExamplesCarousel();

  function initCompaniesCarousel() {
    var carouselContainer = $('.companies-carousel');

    if(carouselContainer.length === 0) return false;

    carouselContainer.owlCarousel({
      items: 6,
      nav: true,
      navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">\n' +
        '    <path fill="#CCC" fill-rule="nonzero" d="M.24 7.422L7.407.24a.816.816 0 0 1 1.155 0l.577.578a.82.82 0 0 1 0 1.157L3.127 8l6.013 6.024a.82.82 0 0 1 0 1.158l-.577.578a.815.815 0 0 1-1.155 0L.239 8.578a.818.818 0 0 1 0-1.156z"/>\n' +
        '</svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">\n' +
        '    <path fill="#CCC" fill-rule="nonzero" d="M9.14 7.422L1.971.24a.816.816 0 0 0-1.155 0L.24.818a.82.82 0 0 0 0 1.157L6.253 8 .239 14.024a.82.82 0 0 0 0 1.158l.577.578c.32.32.836.32 1.155 0L9.14 8.578a.818.818 0 0 0 0-1.156z"/>\n' +
        '</svg>'
      ],
      dots: false,
      responsive: {
        0: {
          items: 2,
        },
        576: {
          items: 4,
        },
        992: {
          items: 6,
        }
      }
    })
  }
  function initAnchorLinks() {
    let anchorLink = $('[data-anchor-link]');

    anchorLink.on('click', function(e){
      e.preventDefault();

      $('html, body').stop().animate({
        scrollTop: $($(this).data('anchor-link')).offset().top
      }, 500);
    })
  }
  function initIntroVideos() {
    var videoContainer = $('.intro-video-container');
    var videosList = [
      'videos/video-1.mp4',
      'videos/video-2.mp4',
      'videos/video-3.mp4',
      'videos/video-4.mp4',
    ];

    if(videoContainer.length === 0 || videosList.length === 0) return false;

    var selectedVideo = randomNumber(videosList.length);

    videoContainer.append(initIframe(videosList[selectedVideo - 1]));

    function initIframe(url){
      return '<video autoplay loop>' +
        '<source src="' + url + '" type="video/mp4">' +
        'Your browser does not support the video tag.' +
      '</video>';
    }
    function randomNumber(counter){
      return Math.floor((Math.random() * counter) + 1);
    }
  }
  function initFormsSending() {
    let forms = document.getElementsByClassName('needs-validation');

    $('.control-phone').inputmask('+7(999)999-99-99');
    $('.control-email').inputmask('email');

    let validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        event.preventDefault();
        event.stopPropagation();

        form.classList.add('was-validated');

        if(form.checkValidity()){
          let data = {};
          let modal = 'successMessageModal';

          data.formName = form.name;

          if(form.name === 'callback-1'){
            data.email = form[0].value;
            data.phone = form[1].value;
            data.name = form[2].value;
            data.message = form[3].value;
            data.submit = 'callback-1';
            $('#' + modal).find('.text').text('Ваша заявка отправлена!');
          }
          if(form.name === 'callback-2'){
            data.name = form[0].value;
            data.phone = form[1].value;
            data.email = form[2].value;
            data.projectDesc = form[3].value;
            data.submit = 'callback-2';
            $('#' + modal).find('.text').text('Ваша заявка отправлена!');
          }
          if(form.name === 'callback-3'){
            data.email = form[0].value;
            data.name = form[1].value;
            data.phone = form[2].value;
            data.projectDesc = form[3].value;
            data.submit = 'callback-3';
            $('#' + modal).find('.text').text('Ваша заявка отправлена!');
          }

          $.ajax({
            type: 'POST',
            url: form.dataset.formAction || './mail/send.php',
            data: data
          }).done(function(){
            $('.modal').modal('hide');
            $('#' + modal).modal('show');
          });
        } else {
          console.log('Error');
        }
      }, false);
    });
  }
  function initExamplesCarousel() {
    var carouselContainer = $('.examples-carousel');

    if(carouselContainer.length === 0) return false;

    carouselContainer.owlCarousel({
      items: 3,
      margin: 0,
      nav: true,
      navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">\n' +
        '    <path fill="#CCC" fill-rule="nonzero" d="M.24 7.422L7.407.24a.816.816 0 0 1 1.155 0l.577.578a.82.82 0 0 1 0 1.157L3.127 8l6.013 6.024a.82.82 0 0 1 0 1.158l-.577.578a.815.815 0 0 1-1.155 0L.239 8.578a.818.818 0 0 1 0-1.156z"/>\n' +
        '</svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">\n' +
        '    <path fill="#CCC" fill-rule="nonzero" d="M9.14 7.422L1.971.24a.816.816 0 0 0-1.155 0L.24.818a.82.82 0 0 0 0 1.157L6.253 8 .239 14.024a.82.82 0 0 0 0 1.158l.577.578c.32.32.836.32 1.155 0L9.14 8.578a.818.818 0 0 0 0-1.156z"/>\n' +
        '</svg>'
      ],
      dots: false,
      loop: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        475: {
          items: 1,
          nav: true,
        },
        575: {
          items: 2,
        },
        800: {
          items: 3,
        }
      }
    })
  }
});
